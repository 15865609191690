import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import Paper from '@material-ui/core/Paper';
import logo from "./logo.png";
import "./Dashboard.css";
import TitleBar from "./TitleBar";

const useStyles = makeStyles({
  grid: {
      padding: '15px',
  },
  media: {
    height: 180
  },
  cardHeight:{
    maxWidth: 345,
    height : 380
  },
  paper: {
    margin: '10px',
    padding: '10px',
    textAlign: 'center',
    color: 'white',
  },
});

const Dashboard = () => {
  const classes = useStyles();

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <TitleBar
              name="Artificial Intelligence Platform"
              tags={['Big Data', 'Data Mining', 'Forecasting', 'Machine Learning', 'Regression', 'Classification', 'Clustering']}
              logo={logo}
              description="An AI-solution that provides personalized alogrithms to help solve your most important business problems end-to-end."/>
        </Grid>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            Machine learning and deep learning find hidden insights in data without being explicitly told where to look or what to conclude. Our artificial intelligence solutions include comprehensive and intuitive machine learning tools with automated feature engineering capabilities, resulting in better recommendations for making smarter decisions in less time.
          </Paper>
        </Grid>
        <Grid className={classes.grid} item xs={12} sm={6} md={3} lg={3}>
          <Card className={classes.cardHeight} spacing={4}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={require("./images/forecasting.jpg")}
                title="Forecasting"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Forecasting
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  It consists of estimating and analyzing said future demand using algorithms that consider many influencing variables, such as sales records, marketing estimates, promotions, campaigns, market studies, dashboards, to optimize the flow of information in the supply chain and prepare the different areas of the organization that are affected.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid className={classes.grid} item xs={12} sm={6} md={3} lg={3}>
          <Card className={classes.cardHeight} spacing={4}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={require("./images/neural.jpeg")}
                title="Neural Networks"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Computer Vision
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  It consists of a set of methods to acquire, process, analyze and understand real world images in order to produce numerical or symbolic information so that they can be processed by a computer. Artificial vision tries to produce the same effect so that computers can perceive and understand an image or sequence of images and act as appropriate in a given situation.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid className={classes.grid} item xs={12} sm={6} md={3} lg={3}>
          <Card className={classes.cardHeight} spacing={4}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={require("./images/tree.jpg")}
                title="Classification"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Pattern Recongition
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  These are algorithms such as association rules to find relationships between data or Neural Networks capable of recognizing handwritten text, or recognition of objects or faces, etc. The objective of Machine Learning is to learn from the data and deliver a reasonable result based on the observations, that means classification of data.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
        <Grid className={classes.grid} item xs={12} sm={6} md={3} lg={3}>
          <Card className={classes.cardHeight} spacing={4}>
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={require("./images/clustering.png")}
                title="Knowledge Discovery"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Knowledge Discovery
                </Typography>
                <Typography variant="body2" color="textSecondary" component="p">
                  It is the non-trivial process of identifying valid, novel, potentially useful, and understandable patterns from the data. The knowledge extracted must be relevant knowledge that is hidden in the database and that its extraction brings benefits, and obviously, it is previously unknown knowledge.
                </Typography>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default Dashboard;
